// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-index-jsx": () => import("./../../../src/templates/AboutUs/index.jsx" /* webpackChunkName: "component---src-templates-about-us-index-jsx" */),
  "component---src-templates-amc-detail-amc-details-js": () => import("./../../../src/templates/AmcDetail/amcDetails.js" /* webpackChunkName: "component---src-templates-amc-detail-amc-details-js" */),
  "component---src-templates-amc-list-amc-js": () => import("./../../../src/templates/AmcList/amc.js" /* webpackChunkName: "component---src-templates-amc-list-amc-js" */),
  "component---src-templates-best-mutual-funds-index-jsx": () => import("./../../../src/templates/BestMutualFunds/index.jsx" /* webpackChunkName: "component---src-templates-best-mutual-funds-index-jsx" */),
  "component---src-templates-calculator-calculator-landing-index-js": () => import("./../../../src/templates/Calculator/CalculatorLanding/index.js" /* webpackChunkName: "component---src-templates-calculator-calculator-landing-index-js" */),
  "component---src-templates-calculator-index-js": () => import("./../../../src/templates/Calculator/index.js" /* webpackChunkName: "component---src-templates-calculator-index-js" */),
  "component---src-templates-category-category-js": () => import("./../../../src/templates/Category/Category.js" /* webpackChunkName: "component---src-templates-category-category-js" */),
  "component---src-templates-collection-index-js": () => import("./../../../src/templates/Collection/index.js" /* webpackChunkName: "component---src-templates-collection-index-js" */),
  "component---src-templates-contact-us-index-jsx": () => import("./../../../src/templates/ContactUs/index.jsx" /* webpackChunkName: "component---src-templates-contact-us-index-jsx" */),
  "component---src-templates-disclaimer-index-jsx": () => import("./../../../src/templates/Disclaimer/index.jsx" /* webpackChunkName: "component---src-templates-disclaimer-index-jsx" */),
  "component---src-templates-elss-index-jsx": () => import("./../../../src/templates/Elss/index.jsx" /* webpackChunkName: "component---src-templates-elss-index-jsx" */),
  "component---src-templates-fa-qs-components-category-page-index-js": () => import("./../../../src/templates/FAQs/components/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-fa-qs-components-category-page-index-js" */),
  "component---src-templates-fa-qs-components-qa-page-index-js": () => import("./../../../src/templates/FAQs/components/QAPage/index.js" /* webpackChunkName: "component---src-templates-fa-qs-components-qa-page-index-js" */),
  "component---src-templates-fa-qs-index-js": () => import("./../../../src/templates/FAQs/index.js" /* webpackChunkName: "component---src-templates-fa-qs-index-js" */),
  "component---src-templates-investor-charter-index-jsx": () => import("./../../../src/templates/InvestorCharter/index.jsx" /* webpackChunkName: "component---src-templates-investor-charter-index-jsx" */),
  "component---src-templates-landing-page-index-jsx": () => import("./../../../src/templates/LandingPage/index.jsx" /* webpackChunkName: "component---src-templates-landing-page-index-jsx" */),
  "component---src-templates-loan-compare-index-js": () => import("./../../../src/templates/LoanCompare/index.js" /* webpackChunkName: "component---src-templates-loan-compare-index-js" */),
  "component---src-templates-mutual-fund-seo-page-index-js": () => import("./../../../src/templates/MutualFundSEOPage/index.js" /* webpackChunkName: "component---src-templates-mutual-fund-seo-page-index-js" */),
  "component---src-templates-premier-index-js": () => import("./../../../src/templates/Premier/index.js" /* webpackChunkName: "component---src-templates-premier-index-js" */),
  "component---src-templates-privacy-policy-index-jsx": () => import("./../../../src/templates/PrivacyPolicy/index.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-index-jsx" */),
  "component---src-templates-risk-warning-index-jsx": () => import("./../../../src/templates/RiskWarning/index.jsx" /* webpackChunkName: "component---src-templates-risk-warning-index-jsx" */),
  "component---src-templates-sahi-hai-page-index-jsx": () => import("./../../../src/templates/SahiHaiPage/index.jsx" /* webpackChunkName: "component---src-templates-sahi-hai-page-index-jsx" */),
  "component---src-templates-section-80-c-index-jsx": () => import("./../../../src/templates/Section80C/index.jsx" /* webpackChunkName: "component---src-templates-section-80-c-index-jsx" */),
  "component---src-templates-switch-switch-js": () => import("./../../../src/templates/Switch/Switch.js" /* webpackChunkName: "component---src-templates-switch-switch-js" */),
  "component---src-templates-terms-of-use-index-jsx": () => import("./../../../src/templates/TermsOfUse/index.jsx" /* webpackChunkName: "component---src-templates-terms-of-use-index-jsx" */),
  "component---src-templates-top-mf-top-mf-js": () => import("./../../../src/templates/TopMf/TopMf.js" /* webpackChunkName: "component---src-templates-top-mf-top-mf-js" */)
}

